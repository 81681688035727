import * as utils from './lib/utils/utils';

const $doc = $( document )

const $navigation = $( '[rel^="js-navigation"' );
const $navigationMenu = $navigation.find( '[rel*="menu"]' );

$doc.on( 'ready', ( event ) => {

  utils.preventDefault( event );

  const $navigationMenuControlGroup = $navigation.find( '[rel*="control-group"]' );
  const $navigationMenuControlActivate = $navigationMenuControlGroup.find( '[rel*="control-activate"]' );
  const $buttonHamburger = $( '[rel*="js-"][rel*="hamburger"]' );

  $navigationMenuControlActivate.on( 'click', ( event ) => {
    $buttonHamburger.toggleClass( 'is-active' );
    $navigationMenu.toggleClass( 'is-active' );
  } );

  setMenuIsHover( event.target )

} );

$doc.on( 'scroll', ( event ) => {

  setMenuIsHover( event.target )

} )

function setMenuIsHover( scrollElem ) {

  const st = $( scrollElem ).scrollTop()
  if ( st > 0 ) {
    $navigationMenu.addClass( 'is-hover' )
  } else if ( st === 0 ) {
    $navigationMenu.removeClass( 'is-hover' )
  }

}
